import React from 'react'
import Theme, { Container, Layout, SectionHero } from '../components'

const ErrorPage = (): React.ReactElement => (
  <Theme>
    <Layout>
      <Container>
        <SectionHero
          title="Großartig!"
          titleTag="h1"
          subtitle="Deine Demo wurde gebucht"
          text="Vielen Dank für dein Interesse an payactive. Du hast erfolgreich
          eine Demo mit uns gebucht. Eine E-Mail mit den Meeting-Details ist
          unterwegs zu dir. Alle weiteren Daten findest du da. Wir freuen uns
          sehr auf das Gespräch."
          actionLinks={[
            {
              label: 'Zurück zur Homepage',
              url: '/',
            },
          ]}
        />
      </Container>
    </Layout>
  </Theme>
)

export default ErrorPage
